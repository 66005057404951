document.getElementById('twitter-link').onclick = function(){
  window.open('https://twitter.com/_fbrz', '_blank');
};

document.getElementById('instagram-link').onclick = function(){
  window.open('https://instagram.com/_fbrz', '_blank');
};

document.getElementById('codepen-link').onclick = function(){
  window.open('https://codepen.io/_fbrz', '_blank');
};

document.getElementById('github-link').onclick = function(){
  window.open('https://github.com/fabrizio-bianchi', '_blank');
};

document.getElementById('mail-link').onclick = function(){
  window.open('mailto:hello@fabrizio.io', '_blank');
};